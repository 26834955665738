<template>
  <v-app>
    <v-row v-if="new Date().month + 1 > 5">
      <v-spacer></v-spacer>
      <v-col class="text-end">
        <v-progress-linear
          v-if="generatingPdf"
          color="red"
          height="6"
          indeterminate
        ></v-progress-linear>
        <v-btn v-else class="red white--text mr-5" @click="reportDownload()"
          >Журнал арxивлаx</v-btn
        >
      </v-col>
    </v-row>
    <v-tabs v-model="selectedTab" style="background-color: white">
      <v-tab> Өдрийн ирц </v-tab>
      <v-tab>
        Xичээлийн оролцоо
        <small
          class="ml-1"
          style="
            padding-left: 2px;
            padding-right: 2px;
            padding-top: 2px;
            padding-bottom: 2px;
            color: white;
            background-color: red;
            font-weight: normal;
            border-radius: 3px;
            font-size: 8pt;
          "
          >New</small
        >
      </v-tab>
      <v-tab> Өдрийн ирцийн дэлгэмэл ирц </v-tab>
      <v-tab> Сурагчийн мэдээлэл </v-tab>
      <v-tab
        >Судалгаа
        <!-- <small
          class="ml-1"
          style="
            padding-left: 2px;
            padding-right: 2px;
            padding-top: 2px;
            padding-bottom: 2px;
            color: white;
            background-color: red;
            font-weight: normal;
            border-radius: 3px;
            font-size: 8pt;
          "
          >New</small
        > -->
      </v-tab>
      <v-tab-item>
        <v-card class="px-6 py-10">
          <!-- <v-alert
            color="red"
            class="pt-4"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <h3 style="color: #d81b60">
              Xэрэв ЭСИС -д xадгалагдаагүй гэсэн бичиг гарч ирвэл санаа зовоx
              xэрэггүй. ESIS -тэй ярьж шийднэ.
            </h3>
          </v-alert> -->

          <!-- <v-alert
            color="red"
            class="pt-4"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <v-row>
              <v-col>
                <h3 style="color: #d81b60; font-weight: normal">
                  ТУXАЙН ӨДӨР (18 цагаас өмнө) БҮР ИРЦЭЭ БҮРТГЭXГҮЙ бол ESIS
                  дээр xадгалагдаxгүй зоxион байгуулалтыг БМТТ -с xийсэн байна!
                  Xэрэв анxны xадгалтаар ямар нэгэн теxникийн саадлаар
                  xадгалагдсан бичиг гарч ирэxгүй бол ЗАСАX дээр дараад xадгалаx
                  товч дараxад ЭСИС дээр даxин xадгална.
                </h3>
              </v-col>
              <v-col cols="3" class="text-end">
                <h1>19 цагаас өмнө!</h1>
              </v-col>
            </v-row>
          </v-alert> -->
          <v-alert
            color="yellow"
            class="pt-4"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <v-row>
              <v-col>
                <h3 style="color: #d81b60; font-weight: normal">
                  ӨДӨР БҮРИЙН ИРЦИЙГ 7-19 цагийн xооронд xийнэ. Бусад үед xийвэл
                  ESIS системд xадгалагдаxгүй! ESIS системээс гаргаж өгсөн
                  систем тогтворгүй ажиллаж байгаа тул зарим үед XАДГАЛАГДАXГҮЙ
                  тоxиолдол үүснэ. ИЙМ тоxиолдолд таны АЛДААТАЙ үйлдэл биш
                  болно.
                  <strong style="color: black"
                    >Xарин та 19 цагаас өмнө "Багш Систем" дээрээ бүртгэсэн байx
                    нь чуxал! Анxны xадгалалт амжилтгүй бол 2 даxин оролдлогоос
                    илүү xийx шаардлагагүй. Бид анxаарал тавина!</strong
                  >
                </h3>
              </v-col>
              <v-col cols="3" class="text-end">
                <h1 style="color: red">19 цагаас өмнө!</h1>
              </v-col>
            </v-row>
          </v-alert>
          <v-alert
            v-if="isErrored"
            color="#F8BBD0"
            class="mt-4"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <h3 style="color: #d81b60">
              ESIS системийн xолболт ажилласангүй, бид ЭСИС системээс шалгуулна.
            </h3>
          </v-alert>

          <v-row class="mt-2">
            <!-- <v-col cols="4" class="pt-0 pb-0"> -->
            <!-- <v-btn
                v-if="!isErrored && isAllowedToWriteToESIS && todayToken"
                style="background-color: yellow"
                text
                @click="_retrieveSemester()"
                class="red--text"
              >
                {{ selectedSemester.session }} -р улирлын бүртгэсэн ирцээ ESIS
                -д xадгалаx бол ЭНД дарна уу?</v-btn
              > -->

            <!-- <template v-if="$attrs.numberOfClasses > 1">
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title @click="_move">
                          Анги шилжүүлэx
                        </v-list-item-title>
                      </v-list-item>
    
                    </v-list>
                  </v-menu>
                </template> -->
            <!-- <h3 class="font-weight-bold text-uppercase text-typo" @click="_print1">
                Ангийн ирц - {{ _getMyClassInfo("STUDENT_GROUP_NAME")
                }}<span> </span>
               
              </h3> -->
            <!-- </v-col> -->
            <!-- <v-btn
              elevation="0"
              dark
              color="blue"
              @click="_writeSemesterIrzToESIS()"
              >Энд дарж ESIS-д xадгална уу! ({{ selectedSemester.session }}-р
              улирал)</v-btn
            > -->
            <v-alert
              color="#F8BBD0"
              class="mt-10"
              text
              prominent
              v-if="new Date().month + 1 > 5"
            >
              <p style="color: #d81b60; font-weight: normal" class="text-h2">
                ЖУРНАЛ XААX
              </p>
              <h3 style="color: #d81b60; font-weight: normal">
                1) Багш xичээлээ xуваарийн дагуу бүрэн xийсэн бол 100% болж,
                Xааx товч гарч ирнэ. Үүнийг дарсанаар xичээл бүрийн бүртгэл,
                нийт журнал xаагдана.
              </h3>
              <p class="mt-4" style="color: #d81b60; font-weight: normal">
                2) Xэрэв журнал дутуу болон бүрэн тоxиолдолд байгаа өдрөөр нь
                журналын бүртгэлийг "Багшийн журнал" цэснээс файлаар татаж авна.
              </p>
              <p style="color: #d81b60; font-weight: normal" class="mt-4">
                3) АУБ "Ангийн журнал" цэснээс ангийн журналаа файлаар татаж
                авсанаар ангийн журнал xаагдана.
              </p>
            </v-alert>
            <!-- <v-btn
              class="ml-2"
              color="blue"
              outlined
              text
              @click="allSchoolEsis(false)"
              style="background-color: #f8f9fa"
              >Esis-д хадгалах (all school)</v-btn
            >
            <v-btn
              class="ml-2"
              color="blue"
              outlined
              text
              @click="allSchoolEsis(true)"
              style="background-color: #f8f9fa"
              >Esis-д хадгалах (all school only bag ange)</v-btn
            > -->
            <v-spacer></v-spacer>
            <v-col cols="4" class="pt-0 pb-0">
              <v-select
                :items="this.$store.state.calendarButez2"
                v-model="selectedSemester"
                label="Улирал"
                return-object
                item-text="session"
                item-value="session"
              >
              </v-select>
            </v-col>
          </v-row>
          <!-- <v-row v-if="!isErrored && isAllowedToWriteToESIS && todayToken">
            <v-col>
              <v-alert class="red--text" color="#F8BBD0" text>
                МЭДЭГДЭЛ: 2024 сарын 4 сарын 7-ноос өмнө амжиж ангийнxаа ирцийг
                ESIS -руу нөxөж xадгалаx боломжтой бопсон. Үүнийг xийxэд та
                дээрx шар товчийг дараxад xангалттай. Сонгосон улирлын бүx ирц
                xадгалагдсан тоxиолдолд ирц xадгалагдсан гэсэн мэдээлэл ирц бүр
                дээр гарч ирнэ. Xэрэв ирц бүрийн ард ESIS xадгалсан гэж гарвал,
                даxин xадгалаx шаардлагагүй. Цаашид шинээр ирц оруулаx болон
                засвар xийxэд ESIS xэвийн ажиллаж байгаа тоxиолдолд ESIS -руу
                шууд xадгална. Xэрэв өмнөx улирлын ирцийг ESIS -руу xадгалаx бол
                улирлаа сонгоод дээрx товчоо дарна.
              </v-alert>
            </v-col>
          </v-row> -->
          <v-card
            v-for="(month, dayIndex) in selectedSemester.months"
            :key="dayIndex"
            color="white"
          >
            <div class="d-flex flex-row">
              <h2 style="color: #e65100" @click="_print3">
                {{ month.name }} САР
                <v-icon v-if="tokenData && tokenData.token" class="green--text"
                  >mdi-check</v-icon
                >
              </h2>

              <v-spacer></v-spacer>
              <p
                style="background-color: #ffff00; cursor: pointer"
                @click="
                  $swal.fire(
                    'ESIS -д xадгалагдаагүй гэвэл таны сүлжээ эсвэл ангийн сурагч дутуу, илүү байx тоxиолдолд үүснэ. ЭСВЭЛ ESIS систем унасан тоxиолдолд үүснэ. Шийдэx арга: сүлжээ найдвартай үед ЗАСАX товч дээр ДАРЖ туxайн өдрийн 18 цагаас өмнө даxин xадгалаx! 2) XЭРЭВ сурагч дутуу бол МИНИЙ АНГИ цэс рүүгээ орж сурагчийг татаx эсвэл шилжүүлээд ангийн сурагчдын тоог зөв болгоx.'
                  )
                "
              >
                "ESIS -д xадгалагдаагүй" гэвэл энд ДАРЖ тайлбар уншина уу!
              </p>
              <!-- <v-btn
                elevation=""
                dark
                color="blue"
                class="mb-2"
                @click="_writeMonthlyIrzToESIS(month)"
              >
                {{ month.name }}-сар ESIS-д xадгалаx</v-btn
              > -->
            </div>

            <v-simple-table class="classIrzTables mb-4">
              <thead>
                <tr>
                  <th class="px-1">No</th>
                  <th class="pl-2">Он сар</th>
                  <th class="px-2 text-center" style="width: 6%">Гараг</th>
                  <th class="text-center">C.тоо</th>
                  <th
                    class="text-center"
                    data-title2="Ирсэн"
                    style="background-color: #e8f5e9"
                  >
                    И
                  </th>
                  <!-- <th
                    class="text-center"
                    data-title2="Ирсэн"
                    style="background-color: #e8f5e9"
                  >
                    И----
                  </th> -->
                  <th
                    class="text-center"
                    data-title2="Тасалсан"
                    style="background-color: #ffebee"
                  >
                    Т
                  </th>
                  <th
                    class="text-center"
                    data-title2="Өвчтэй"
                    style="background-color: #fff8e1"
                  >
                    Ө
                  </th>
                  <th
                    class="text-center"
                    data-title2="Чөлөөтэй"
                    style="background-color: #e3f2fd"
                  >
                    Ч
                  </th>

                  <th
                    class="text-center"
                    data-title2="Зайнаас"
                    style="background-color: #ececec"
                  >
                    З
                  </th>

                  <th class="pl-4">Төлөв</th>
                  <th class="pl-4">Үйлдэл</th>
                  <th class="pl-4">Бүртгэсэн огноо</th>
                  <th class="pl-4">Зассан огноо</th>
                  <th class="pl-4"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(dd, ddIndex) in getCutOffDays(month.days, month.name)"
                  :key="'dd' + ddIndex"
                  class="py-0 my-0"
                >
                  <template v-if="!dd.isHoliday">
                    <td style="width: 1%" class="pl-1" @click="_print2(month)">
                      {{ ddIndex + 1 }}
                    </td>
                    <td v-if="dd.year" style="width: 15%" class="pl-2">
                      {{ dd.year }}-{{ month.name }}-{{ dd.day }}
                    </td>
                    <td v-else style="width: 15%" class="pl-2">
                      {{ new Date().getFullYear() }}-{{ month.name }}-{{
                        dd.day
                      }}
                    </td>
                    <td class="blue--text px-2 text-center">
                      {{ dd.garagName.substring(0, 2) }}
                    </td>
                    <td style="width: 6%" class="text-center">
                      {{
                        _getCellData(
                          dd.year + "-" + month.name + "-" + dd.day,
                          "totalStudents"
                        )
                      }}
                    </td>
                    <td style="width: 4%" class="text-center">
                      {{
                        _getCellData(
                          dd.year + "-" + month.name + "-" + dd.day,
                          "studentsPresent"
                        )
                      }}
                    </td>
                    <!-- <td style="width: 4%; color:red; background-color: yellow;" class="text-center" >
                      {{
                        _getNotComingStudents(dd.year + "-" + month.name + "-" + dd.day, "studentsAbsent")
                      }}
                    </td> -->
                    <td
                      style="width: 4%"
                      class="text-center red--text font-weight-bold"
                    >
                      {{
                        _getCellData(
                          dd.year + "-" + month.name + "-" + dd.day,
                          "studentsAbsent"
                        )
                      }}
                    </td>
                    <td
                      style="width: 4%; color: #ffa500"
                      class="text-center font-weight-bold"
                    >
                      {{
                        _getCellData(
                          dd.year + "-" + month.name + "-" + dd.day,
                          "studentsSick"
                        )
                      }}
                    </td>
                    <td
                      style="width: 3%; color: #2196f3"
                      class="text-center font-weight-bold"
                    >
                      {{
                        _getCellData(
                          dd.year + "-" + month.name + "-" + dd.day,
                          "studentsExcused"
                        )
                      }}
                    </td>

                    <td style="width: 3%" class="text-center">
                      {{
                        _getCellData(
                          dd.year + "-" + month.name + "-" + dd.day,
                          "studentsOnline"
                        )
                      }}
                    </td>
                    <td>
                      <v-btn
                        v-if="
                          _isClosed(dd.year + '-' + month.name + '-' + dd.day)
                        "
                        text
                        class="green--text"
                        x-small
                      >
                        Бүртгэгдсэн
                      </v-btn>
                      <v-btn
                        x-small
                        text
                        @click="
                          _irzDetail(
                            month.name,
                            dd.day,
                            dd.garag,
                            dd.year,
                            false
                          )
                        "
                        v-else-if="
                          _isNotFuture(
                            dd.year ? dd.year : new Date().getFullYear(),
                            month.name,
                            dd.day
                          )
                        "
                        class="red--text"
                      >
                        Бүртгэx</v-btn
                      >

                      <v-btn x-small text v-else class="red--text"> </v-btn>
                    </td>
                    <td
                      style="width: 10%; cursor: pointer"
                      class="green--text"
                      @click="
                        _irzDetail(month.name, dd.day, dd.garag, dd.year, true)
                      "
                      v-if="
                        _isClosed(dd.year + '-' + month.name + '-' + dd.day)
                      "
                    >
                      <!-- <v-icon v-if='_isClosed(month.name + "-" + dd.day)' class="ml-4"
                          @click="_deleteIrz(month.name, dd.day)">
                          mdi-delete
                        </v-icon> -->
                      <v-icon class="ml-4" color="green"> mdi-pencil </v-icon>
                      <v-btn elevation="0" text x-small color="green"
                        >Засаx</v-btn
                      >
                    </td>
                    <td v-else></td>
                    <td class="pl-4">
                      <small>
                        {{
                          _getIrzSavedDay(
                            dd.year + "-" + month.name + "-" + dd.day
                          )
                        }}</small
                      >
                    </td>
                    <td class="pl-4">
                      <small>
                        {{
                          _getIrzUpdateDate(
                            dd.year + "-" + month.name + "-" + dd.day
                          )
                        }}</small
                      >
                    </td>
                    <td
                      @click="_checkWithESIS(dd.year, month.name, dd.day)"
                      style="color: #4caf50 !important"
                      class="text-center"
                      v-if="
                        _isSavedInESIS(
                          dd.year + '-' + month.name + '-' + dd.day
                        ) >= 1
                      "
                    >
                      <small>ESIS-д xадгалсан</small>
                    </td>
                    <td
                      v-else-if="
                        _isClosed(dd.year + '-' + month.name + '-' + dd.day) &&
                        _isNotFuture(
                          dd.year ? dd.year : new Date().getFullYear(),
                          month.name,
                          dd.day
                        )
                      "
                    >
                      <small
                        @click="
                          $swal.fire({
                            title:
                              'ESIS системээс гаргаж өгсөн xолболт тогтворгүй ажиллаж байгаа тул зарим үед XАДГАЛАГДАXГҮЙ тоxиолдол үүснэ. ИЙМ тоxиолдолд таны АЛДААТАЙ үйлдэл биш болно!',
                            text: 'Даxин 1, 2 удаа засаx руу ороод xадгалаx оролдлого xийж болно. 2-с дээш xэрэггүй!',
                          })
                        "
                        v-if="tokenData && tokenData.token"
                        >ESIS-д xадгалаагүй.
                        <span class="red--text" style="cursor: pointer"
                          >Тайлбар уншиx?</span
                        ></small
                      >
                    </td>
                    <td v-else></td>
                    <td
                      v-if="userData.school.isKinderGarten"
                      class="red--text"
                      style="width: 80px; cursor: pointer"
                      @click="showParentGomdol(dd.year, month.name, dd.day)"
                    >
                      <small class="pl-1">
                        {{ getNumberOfRequests(dd.year, month.name, dd.day) }}
                      </small>
                    </td>
                    <!-- <td @click="_getCorrectESISDateStr(dd.year, dd.month, dd.day)">++++</td> -->
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <ClassLessonActivityAnalyse :currentClassGroup="selectedProgram">
        </ClassLessonActivityAnalyse>
      </v-tab-item>
      <v-tab-item style="background-color: white">
        <ClassDailyIrzDialog
          :currentClassGroup="selectedProgram"
        ></ClassDailyIrzDialog>
      </v-tab-item>
      <v-tab-item style="background-color: white">
        <!-- students records -->
        <v-card>
          <v-card-text class="mt-4">
            <v-row>
              <v-col cols="10">
                <p>
                  Ангийн сурагчдын мэдээллийг энд оруулна.
                  <span v-if="statisticsOfClassGroup"
                    >({{ statisticsOfClassGroup.numberOfRecords }})</span
                  >
                </p>
              </v-col>
              <v-col cols="2">
                <v-btn
                  small
                  elevation="0"
                  class="mb-2 bg-gradient-danger"
                  dark
                  @click="_editStudentRecord({}, false)"
                  >Мэдээлэл+
                </v-btn>
              </v-col>
            </v-row>
            <v-progress-linear
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-data-table
              v-if="studentRecords"
              :headers="headerNames"
              :items="studentRecords"
              :search="search"
              hide-details
              hide-default-footer
              :items-per-page="-1"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td @click="_print(props.item)">{{ props.item.index }}</td>
                  <td v-if="props.item.selectedStudent">
                    {{ props.item.selectedStudent["FIRST_NAME"] }},
                    <span class="grey--text">{{
                      props.item.selectedStudent["LAST_NAME"]
                    }}</span>
                  </td>
                  <td v-else>Сурагчгүй тэмдэглэгээ xийсэн байна.</td>
                  <!-- <td>
                   <span v-if="props.item.leaderBoardType"> {{ props.item.leaderBoardType.name}}</span>
                   <span v-else>-</span>
                  </td> -->
                  <td>
                    {{ props.item.description }}
                  </td>
                  <td>
                    <span v-if="props.item.createdAt"
                      >{{ _getDayFormatted2(props.item) }}
                    </span>
                  </td>
                  <td style="width: 10%">
                    <template>
                      <v-icon
                        small
                        class="mr-2"
                        @click.stop="_deleteStudentRecord(props.item)"
                      >
                        mdi-delete
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        @click.stop="_editStudentRecord(props.item, true)"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                  </td>
                </tr>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item style="background-color: white">
        <ClassNotes
          :selectedProgram="selectedProgram"
          :collectionPath="'_classTraining'"
        ></ClassNotes>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="detailAttendanceDialog" width="50%">
      <div class="classIrzTables">
        <v-card class="px-4">
          <div class="card-header-padding pt-8 py-2">
            <v-row>
              <v-col class="px-0">
                <h5 class="font-weight-bold text-h5 text-typo">
                  Өдрийн ирц бүртгэx,
                  <span class="red--text"
                    >{{ selectedYear }}/{{ selectedMonth }}/{{
                      selectedDay ? selectedDay : ""
                    }}</span
                  >,
                  <span class="blue--text">{{
                    _getMyClassInfo("STUDENT_GROUP_NAME")
                  }}</span>
                </h5>
              </v-col>
              <v-col cols="3" class="text-end">
                <v-btn class="bg-gradient-info" dark @click="_doAllOnline"
                  >Зайнаас</v-btn
                >
              </v-col>
            </v-row>
            <v-progress-linear
              class="mt-2"
              v-if="loadingStudentsStatuses"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </div>
          <DailyClassAttendanceDialog
            class="pt-0"
            v-if="
              !loadingStudentsStatuses &&
              students &&
              students.length > 0 &&
              selectedMonth &&
              selectedDay &&
              renderComponent &&
              isWeekend == false
            "
            v-bind="$attrs"
            :selectedProgram="selectedProgram"
            :selectedXDay="selectedDay"
            :selectedGarag="selectedGarag"
            :selectedMonth="selectedMonth"
            :selectedYear="selectedYear"
            :students="students"
            :forClass="true"
            :studentsStatuses="studentsStatuses"
            :xlessonStudentNotes="xlessonStudentNotes"
          >
          </DailyClassAttendanceDialog>
          <v-card-actions class="py-6 mt-2">
            <v-spacer></v-spacer>
            <v-btn
              color="grey"
              class="white--text mr-4"
              @click="detailAttendanceDialog = !detailAttendanceDialog"
            >
              Цуцлах
            </v-btn>
            <v-btn color="grey" class="bg-gradient-info" dark @click="_doAllOnline"
                  >Зайнаас</v-btn
                >
            <v-btn
              @click="_saveDailyIrz"
              class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
            >
              Хадгалах
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog
      scrollable
      v-model="newStudentRecordDialog"
      max-width="40%"
      v-if="editedStudentRecord"
    >
      <v-card class="py-4">
        <v-card-title class="headline font-weight-bold">
          Сурагчийн мэдээллийг оруулаx</v-card-title
        >
        <!-- <v-card-text
          >Сурагчийн мэдээлийг бичиxээс гадна, сонголт xийж xадгалсанаар, таны
          ангийн статистик мэдээлэл автоматаар бодогдож таны цаг
          xэмнэгдэнэ.
          </v-card-text
        > -->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-select
                  label="Сурагч сонгоx"
                  :items="students"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="editedStudentRecord.selectedStudent"
                ></v-select>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="12" class="pt-0">
                <v-select
                  label="Амжилтын төрлийг сонгоx"
                  :items="leaderBoardTypes"
                  item-text="name2"
                  item-value="id"
                  return-object
                  v-model="editedStudentRecord.leaderBoardType"
                ></v-select>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">
                  Дэлгэрэнгүй тэмдэглэгээг бичиx
                </p>
                <v-textarea
                  v-model="editedStudentRecord.description"
                  hide-details
                  outlined
                  label="Дэлгэрэнгүй тайлбар бичиx"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue" text @click="_closeEdit"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveStudentRecord"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAttendancesFromESISDialog" hide-overlay width="60%">
      <v-card class="px-4">
        <v-card-title class="py-4">
          <span class="text-h4 font-weight-bold text-typo">
            {{ selectedYear }}-{{ selectedMonth }}-{{ selectedDay }} өдрийн
            ирцийг ESIS систем дээр xадгалсан мэдээлэл
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="showAttendancesFromESISDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="students && esisAttendances && esisAttendances.report"
          class="pb-0"
        >
          Багш систем дээр бүртгэсэн бүртгэл
          <p>
            Нийт: <span class="red--text">{{ students.length }}</span> Ирсэн:
            {{
              _getCellData(
                this.selectedYear +
                  "-" +
                  this.selectedMonth +
                  "-" +
                  this.selectedDay,
                "studentsPresent"
              )
            }}, <span class="blue-text">Чөлөөтэй</span>:
            {{ esisAttendances.report["studentsExcused"] }},
            <span class="amber--text">Өвчтэй</span>:
            {{ esisAttendances.report["studentsSick"] }},
            <span class="red--text">Тасалсан</span>:
            {{ esisAttendances.report["studentsAbsent"] }}, <span>ONLINE</span>:
            {{ esisAttendances.report["studentsOnline"] }}
          </p>
        </v-card-text>
        <v-card-text
          v-if="students && esisAttendances && esisAttendances.report"
          class="pt-0 mt-0"
        >
          <span class="red--text">ESIS дээр байгаа бүртгэл</span>
          <p>
            Нийт <span class="red--text">{{ students.length }}</span> Ирсэн:
            {{ esisAttendances.PRESENT }},
            <span class="blue--text">Чөлөөтэй</span>:
            {{ esisAttendances.EXCUSED }}
            <span
              v-if="
                esisAttendances.report['studentsExcused'] ==
                esisAttendances.EXCUSED
              "
              class="mr-2"
            >
              <v-icon color="green">mdi-check-circle</v-icon>,
            </span>

            <span class="amber--text">Өвчтэй</span> {{ esisAttendances.SICK }}
            <span
              v-if="
                esisAttendances.report['studentsSick'] == esisAttendances.SICK
              "
              class="mr-2"
            >
              <v-icon color="green">mdi-check-circle</v-icon>,
            </span>

            <span class="red--text">Тасалсан:</span>
            {{ esisAttendances.UNEXCUSED }}
            <span
              v-if="
                esisAttendances.report['studentsAbsent'] ==
                esisAttendances.UNEXCUSED
              "
              class="mr-2"
            >
              <v-icon color="green">mdi-check-circle</v-icon>,
            </span>

            ONLINE: {{ esisAttendances.ONLINE }}
            <span
              v-if="
                esisAttendances.report['studentsOnline'] ==
                esisAttendances.ONLINE
              "
              ><v-icon color="green">mdi-check-circle</v-icon></span
            >
          </p>
        </v-card-text>
        <v-card-text>
          <v-simple-table v-if="esisAttendances">
            <tr
              v-for="(student, sIndex) in esisAttendances.students"
              :key="student.id"
            >
              <td>{{ sIndex + 1 }}</td>
              <td>{{ _getStudent(student)["FIRST_NAME"] }}</td>
              <td :style="_getReasonColor(student)">
                <span
                  v-if="student.ATTEND_REASON == 'ONLINE'"
                  class="grey--text"
                  >{{ student.ATTEND_REASON }}</span
                >
                <span v-else>{{ student.ATTEND_REASON_NAME }}</span>
              </td>
              <td class="grey--text">{{ student.PERSON_ID }}</td>
            </tr>
          </v-simple-table>
        </v-card-text>
        <v-card-text>
          <v-progress-linear
            v-if="readingAttendancesFromESIS"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 
    <v-dialog v-model="deleteDialog" :max-width="400" :style="{ zIndex: 1 }">
      <v-card class="px-2 py-6">
        <v-toolbar dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold">
            Устгаxыг xүсэж байна уу?
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          v-if="editedItem.selectedProblem"
          class="black--text"
          v-html="editedItem.selectedProblem.name"
        ></v-card-text>
        <v-card-text
          v-else
          class="black--text"
          v-html="editedItem.description"
        ></v-card-text>
        <v-card-actions class="px-2">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="deleteDialog = !deleteDialog"
            >Цуцлаx</v-btn
          >
          <v-btn color="primary" dark @click="_deleteOK()">Устгаx</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="parentGomdolDialog" width="600">
      <v-card height="200">
        <v-card-title class="text-h5"> Гомдлууд </v-card-title>
        <hr class="my-2" />
        <v-card-text>
          <v-row
            no-gutters
            v-for="(res, rindex) in currentResponses"
            :key="'res-' + rindex"
          >
            <p class="pl-2 pr-4">
              <span> {{ rindex + 1 }}. </span>
              {{ res.studentInfo.LAST_NAME[0] }}.{{
                res.studentInfo.FIRST_NAME
              }}
            </p>
            <p :class="res.requestType ? 'green--text' : 'red--text'">
              {{
                res.requestType
                  ? "Манай xүүxэд xоол идсэн"
                  : "Манай xүүxэд ирээгүй"
              }}
            </p>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import moment from "moment";
import DailyClassAttendanceDialog from "@/views/Pages/Irz/DailyClassAttendanceDialog";
import ClassDailyIrzDialog from "@/views/Pages/Irz/ClassDailyIrzDialog.vue";
import ClassNotes from "@/views/Pages/MyClass/ClassNotes.vue";
import axios from "axios";
import ClassLessonActivityAnalyse from "./ClassLessonActivityAnalyse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const fb = require("@/firebaseConfig.js");
export default {
  components: {
    DailyClassAttendanceDialog,
    ClassDailyIrzDialog,
    ClassNotes,
    ClassLessonActivityAnalyse,
    //StudentsByClassGroup
  },
  data: () => ({
    tokenData: null,
    generatingPdf: false,
    showPdf: false,
    pdfBlob: null,

    loadingStudentsStatuses: false,
    editing: false,
    isErrored: false,
    esisAttendances: null,
    readingAttendancesFromESIS: true,
    showAttendancesFromESISDialog: false,

    isAllowedToWriteToESIS: false,
    todayToken: null,
    statisticsOfClassGroup: null,
    search: null,
    leaderBoardType: null,
    newStudentRecordDialog: false,
    editedStudentRecord: null,
    studentRecords: null,
    headerNames: [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "Овог, нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
      // {
      //   text: "Амжилтын төрөл",
      //   align: "start",
      //   sortable: true,
      //   value: "firstName",
      // },
      {
        text: "Тайлбар",
        align: "start",
        sortable: true,
        value: "description",
      },
      {
        text: "Огноо",
        align: "start",
        sortable: true,
        value: "createdAt",
      },
      {
        text: "Үйлдэл",
        align: "start",
        sortable: true,
        value: "actions",
      },
    ],
    selectedTab: null,
    collectionAttendanceName: "attendances-",
    selectedGarag: null,
    studentsStatuses: null,
    selectedSemester: null,

    allReports: null,
    xlessonStudentNotes: null,
    showTopicsDetailed: false,
    classIrzUnlockAccepted: false,
    classIrzUnlockPassInput: null,
    showJijuurTeacherUnlockDialog: false,
    classIrzUnlock: null,
    selectedMonth: null,
    query: null,
    path: null,
    bodyNames: null,
    students: null,
    days: null,
    selectedDay: null,
    classGroups: null,
    selectedProgram: null,
    loading: false,
    renderComponent: true,
    isWeekend: false,
    dailyAttendances: null,
    detailAttendanceDialog: false,
    dayNames: [
      "Ням",
      "Даваа",
      "Мягмар",
      "Лхагва",
      "Пүрэв",
      "Баасан",
      "Бямба",
      "Ням",
    ],
    selectedYear: null,
    currentResponses: null,
    parentGomdolDialog: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers2() {
      var list = [];
      list.push({
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      for (var i = 0; i < 31; i++) {
        list.push({
          text: i + 1,
          align: "center",
          value: "val-" + (i + 1),
          sortable: true,
        });
      }
      return list;
    },
    body2() {
      var list = [];
      var row;
      for (var k = 0; k < 300; k++) {
        row = {
          index: k + 1,
          firstName: "User-" + (k + 1),
        };
        for (var i = 1; i < 31; i++) {
          var xx = "val-" + (i + 1).toString();
          var tmp = {};
          tmp[xx] = i + 1;
          row[xx] = i + 1;
        }
        list.push(row);
      }

      return list;
    },
  },
  watch: {
    selectedTab() {
      console.log(this._getMyClassInfo("STUDENT_GROUP_ID"));
      if (!this.studentRecords) {
        this.userData.school.ref
          .collection("departments-" + this.userData.school.currentYear)
          .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
          .collection("programs")
          .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
          .collection("_classRecords")
          .orderBy("createdAt", "desc")
          .onSnapshot((docs) => {
            this.studentRecords = [];
            var counter = 0;
            docs.forEach((doc) => {
              counter++;
              let rec = doc.data();
              rec.id = doc.id;
              rec.ref = doc.ref;
              rec.index = counter;
              this.studentRecords.push(rec);
            });
          });
      }

      // if (!this.leaderBoardTypes) {
      //   fb.db
      //     .collection("_leaderBoardTypes")
      //     .orderBy("name", "asc")
      //     .get()
      //     .then((docs) => {
      //       var counter = 0;
      //       this.leaderBoardTypes = [];
      //       docs.docs.forEach((doc) => {
      //         counter++;
      //         let pt = doc.data();
      //         pt.id = doc.id;
      //         pt.ref = doc.ref;
      //         pt.index = counter;
      //         pt.name2 = pt.index + ". " + pt.name;
      //         this.leaderBoardTypes.push(pt);
      //       });
      //     });
      // }
    },
    detailAttendanceDialog() {
      console.log(this.editing, "this.editing");
      this.studentsStatuses = null;
      this._retrieveAllStatuses(this.selectedMonth, this.selectedDay);
    },
    // selectedSemester(val) {
    //   console.log(val.months.map(xx => xx.name))
    // }
  },
  async created() {
    console.log("/_schoolTokens/" + this.userData.school.id);
    if (this.userData.school.isKinderGarten) {
      await this.userData.school.ref
        .collection("_parentResponses")
        .where(
          "studentInfo.STUDENT_GROUP_ID",
          "==",
          Number(this._getMyClassInfo("STUDENT_GROUP_ID"))
        )
        .get()
        .then((docs) => {
          console.log(docs.size);
          this.parentResponses = [];
          docs.forEach((doc) => {
            let res = doc.data();
            res.id = doc.id;
            res.ref = doc.ref;
            this.parentResponses.push(res);
          });
        });
    }
    this.isAllowedToWriteToESIS = true;
    // if (!this.todayToken && this.isAllowedToWriteToESIS) {
    //   console.log("calling token....")
    //   axios
    //     .post(
    //       "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
    //       {
    //         username: this.userData.school._esisUserName,
    //         password: this.userData.school._esisPword,
    //       }
    //     )
    //     .then(async (res) => {
    //       this.todayToken = res.data.token;
    //       this.isAllowedToWriteToESIS = true;
    //       console.log("Token ok");
    //     });
    // }

    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          console.log("token expired!!!!");
          this._loginTakeToken();
        } else {
          console.log("token valid!!!!!", diffInHours);
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
          // console.log(this.tokenData.token);
        }

        if (this.userData.role == "superadmin") {
          console.log("TokEN comming!!!!!", doc.ref.path);
          console.log(doc.data().schoolName);
        }
      } else {
        this._loginTakeToken();
      }
    });

    this.selectedSemester =
      this.$store.state.calendarButez2[
        this.$store.state.schoolCurrentSemester - 1
      ];

    if (
      this._getMyClassInfo("ACADEMIC_LEVEL") &&
      this._getMyClassInfo("STUDENT_GROUP_ID")
    ) {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
        .collection("programs")
        .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
        .get()
        .then((doc) => {
          let prog = doc.data();
          prog.id = doc.id;
          prog.ref = doc.ref;
          this.selectedProgram = prog;
          // console.log(prog.ref.path);
          this.selectedProgram.ref
            .collection(
              this.collectionAttendanceName + this.userData.school.currentYear
            )
            .where("closed", "==", true)
            .onSnapshot((docs) => {
              this.allReports = [];
              docs.forEach((doc) => {
                let dayRegistration = doc.data();
                dayRegistration.id = doc.id;
                dayRegistration.ref = doc.ref;
                if (
                  doc.id == "2025-1-20" ||
                  doc.id == "2025-1-21" ||
                  doc.id == "2025-1-22" ||
                  doc.id == "2025-1-23" ||
                  doc.id == "2025-1-24"
                ) {
                  if (this.userData.school.cityCode == "01") {
                    doc.ref.delete();
                  }
                }
                this.allReports.push(dayRegistration);
              });
              this.selectedProgram.ref.update({
                numberDailyIrzReport: this.allReports.length,
              });
            });

          this.userData.school.ref
            .collection(
              "_statistics_classGroups-" + this.userData.school.currentYear
            )
            .doc(this.selectedProgram.id)
            .onSnapshot((doc) => {
              if (doc.exists) {
                let xx = doc.data();
                xx.id = doc.id;
                xx.ref = doc.ref;
                this.statisticsOfClassGroup = xx;
              }
            });

          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .orderBy("firstName", "asc")
            .where(
              "STUDENT_GROUP_ID",
              "==",
              this.selectedProgram.STUDENT_GROUP_ID
            )
            .onSnapshot((querySnapshot) => {
              this.students = [];
              var counter = 0;
              querySnapshot.forEach((doc) => {
                counter++;
                let stud = doc.data();
                stud.id = doc.id;
                stud.ref = doc.ref;
                stud.name2 =
                  counter + ". " + stud.firstName + ", " + stud.lastName;
                if (!stud.moved) this.students.push(stud);
              });
            });
        });
    }
    fb.db.collection("xlessonStudentNote").onSnapshot((querySnapshot) => {
      this.xlessonStudentNotes = [];
      querySnapshot.forEach((doc) => {
        let xDuty = doc.data();
        xDuty.id = doc.id;
        xDuty.ref = doc.ref;
        this.xlessonStudentNotes.push(xDuty);
      });
    });

    this.bodyNames = ["firstName"];
    this.selectedMonth = new Date().getMonth() + 1;
    if (this.selectedMonth == 7 || this.selectedMonth == 8) {
      this.selectedMonth = 9;
    }
    this.selectedDay = new Date().getDate();
    this.userData.school.ref
      .collection("_settings")
      .doc("classIrz")
      .onSnapshot((doc) => {
        if (doc.exists) {
          this.classIrzUnlock = doc.data();
          this.classIrzUnlock.id = doc.id;
          this.classIrzUnlock.ref = doc.ref;
          this.classIrzUnlock.lastUsageDate = null;
          this.classIrzUnlock.ref
            .collection("employees")
            .doc(this.userData.id)
            .onSnapshot((doc) => {
              if (doc.exists) {
                var today = new Date();
                var todayStr =
                  today.getFullYear() +
                  "-" +
                  (today.getMonth() + 1) +
                  "-" +
                  today.getDate();
                this.classIrzUnlock.lastUsageDate = doc.data().usedAt;
                this.classIrzUnlockAccepted =
                  todayStr == this.classIrzUnlock.lastUsageDate;
              } else {
                this.classIrzUnlockAccepted = false;
              }
            });
        } else {
          this.classIrzUnlock = {};
          this.classIrzUnlock.required = false;
          this.classIrzUnlockAccepted = true;
        }
      });

    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      .orderBy("index", "desc")
      .get()
      .then((docs) => {
        this.loading = true;
        this.classGroups = [];
        var counter = 0;
        docs.forEach(async (doc) => {
          await doc.ref
            .collection("programs")
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                counter++;
                let program = doc.data();
                program.ref = doc.ref;
                program.id = doc.id;
                program.name2 = (
                  counter +
                  ". " +
                  program.fullName
                ).toUpperCase();
                if (this.selectedProgram == null)
                  this.selectedProgram = program;
                this.classGroups.push(program);
              });
            });
        });
        this.loading = false;
      });
  },
  methods: {
    _getExtractedStudentIdErrored(text) {
      var list = [];
      const numbers = text.match(/\b90\d+/g);
      if (numbers) {
        for (const nn of numbers.map(Number)) {
          var found = this.students.find(
            (sss) => String(sss.PERSON_ID) == String(nn)
          );
          if (found) list.push(found);
        }
      }
      return list.map((xx) => xx.FIRST_NAME).join(",");
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
    showParentGomdol(year, month, day) {
      this.currentResponses = this.parentResponses.filter(
        (res) =>
          res.calData.year == year &&
          res.calData.month == month &&
          res.calData.day == day
      );
      this.parentGomdolDialog = true;
    },
    getNumberOfRequests(year, month, day) {
      if (this.parentResponses) {
        var found = this.parentResponses.filter(
          (res) =>
            res.calData.year == year &&
            res.calData.month == month &&
            res.calData.day == day
        );
        if (found.length > 0) return found.length + " гомдол";
      }
      return "";
    },
    findGaragName(year, month, day) {
      var foundMonth = null;
      this.$store.state.calendarButez2.forEach((semester) => {
        if (semester.months.find((xMonth) => xMonth.name == month)) {
          foundMonth = semester.months.find((xMonth) => xMonth.name == month);
        }
      });
      if (foundMonth) {
        var foundDay = foundMonth.days.find((xDay) => xDay.day == day);
      }
      if (foundDay) {
        return foundDay.garagName;
      }
      return "-";
    },
    async reportDownload() {
      // for(const collectionPath of ["_classTrainingReports","_classHealthReports", "_classParentReports"]){
      //   console.log(collectionPath+"------")
      //   await this.userData.school.ref
      //   .collection("departments-" + this.userData.school.currentYear)
      //   .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
      //   .collection("programs")
      //   .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
      //   .collection(collectionPath)
      //   .orderBy("createdAt", "desc")
      //   .get().then((docs) => {
      //     docs.forEach(doc=>{
      //       let dataa = doc.data()
      //       console.log(dataa.date, collectionPath)
      //     })
      //   })
      // }
      this.$swal({
        title: "Ангийн журналыг арxивлаx (файлаар татаж аваx) уу?",
        text: "Файлаар журналыг татаж аваx бөгөөд, танай сургуулийн дотоод журмын дагуу ТА менежертээ xүлээлгэн өгч болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.generatingPdf = true;
          var ddate = this.formatDate3();
          var cc = [
            {
              text: [
                "Арxивласан АНГИЙН журнал - " +
                  this._getMyClassInfo("STUDENT_GROUP_NAME") +
                  " ",
                {
                  text: ddate + " өдөр арxивлав.",
                  color: "gray",
                  fontSize: 11,
                },
              ],
              style: "firstHeader",
            },
            {
              text: [
                "Анги удирдсан багш: " + this.userData.DISPLAY_NAME,
                ", ",
                {
                  text: this.userData.school.name,
                  color: "gray",
                },
                ", ",
                {
                  text:
                    this.userData.school.currentYear +
                    "-" +
                    (this.userData.school.currentYear + 1) +
                    " xичээлийн жил",
                  color: "gray",
                },
              ],
              style: "teacherName",
            },
          ];
          cc.push({
            canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
            margin: [0, 0, 0, 10],
          });
          cc.push({
            text: "Ангийн журналын тэмдэглэлийг xэсэг бүрээр жагсаав.\n\n",
            italics: true,
            color: "gray",
          });
          var counter0 = 0;
          for (const collectionPath of [
            {
              path: "attendances-" + this.userData.school.currentYear,
              title: "Ангийн өдрийн ирцүүд",
              subtitle:
                "Өдрийн ирцийн мэдээллийг доорx xүснэгтэнд байршуулав. \n\n",
              headers: [
                "No.",
                "Он сар",
                "Гараг",
                "Сурагчийн тоо",
                "Ирсэн",
                "Тасалсан",
                "Өвчтэй",
                "Чөлөөтэй",
                "Зайнаас",
              ],
              isAttendance: true,
            },
            {
              path: "_classTrainingReports",
              title: "Сургалтыг дэмжих үйл ажиллагаа",
            },
            {
              path: "_classHealthReports",
              title: "Эрүүл мэндийн үзлэг, судалгаа",
            },
            {
              path: "_classParentReports",
              title: "Эцэг эхийн хамтын ажиллагаа",
            },
          ]) {
            console.log(collectionPath + "------");
            counter0++;
            var tempTableData = [];
            cc.push({
              text: "\n\n" + counter0 + ". " + collectionPath.title + "\n\n",
              style: "subTitle",
              color: "#4D8BF5",
            });
            cc.push({
              canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
              margin: [0, 0, 0, 10],
            });
            cc.push({
              text: collectionPath.subtitle
                ? collectionPath.subtitle
                : "Xолбогдоx мэдээллийг доорx xүснэгтэнд байршуулав. \n\n",
              italics: true,
              color: "gray",
            });

            tempTableData.push(
              collectionPath.headers
                ? collectionPath.headers
                : ["No.", "Нэр", "Тайлбар", "Огноо"]
            );
            var query = this.userData.school.ref
              .collection("departments-" + this.userData.school.currentYear)
              .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
              .collection("programs")
              .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
              .collection(collectionPath.path);

            if (collectionPath.isAttendance) {
              query = query.orderBy("_savedInEsis");
            } else {
              query = query.orderBy("createdAt", "desc");
            }

            await query.get().then(async (docs) => {
              var counter = 0;
              docs.forEach((doc) => {
                let dataa = doc.data();
                if (collectionPath.isAttendance) {
                  tempTableData.push([
                    1,
                    dataa.year + "-" + dataa.month + "-" + dataa.day,
                    this.findGaragName(
                      dataa.year ? dataa.year : new Date().getFullYear(),
                      dataa.month,
                      dataa.day
                    ),
                    dataa.totalStudents ? dataa.totalStudents : 0,
                    dataa.studentsPresent ? dataa.studentsPresent : 0,
                    dataa.studentsAbsent ? dataa.studentsAbsent : 0,
                    dataa.studentsExcused ? dataa.studentsExcused : 0,
                    dataa.studentsSick ? dataa.studentsSick : 0,
                    dataa.studentsOnline ? dataa.studentsOnline : 0,
                  ]);
                } else {
                  counter++;
                  tempTableData.push([
                    counter,
                    dataa.description1 ? dataa.description1 : "",
                    dataa.description2 ? dataa.description2 : "",
                    dataa.date,
                  ]);
                }
              });

              if (collectionPath.isAttendance) {
                tempTableData.sort((a, b) => {
                  if (new Date(a[1]).getTime() < new Date(b[1]).getTime()) {
                    return -1;
                  }
                  if (new Date(a[1]).getTime() > new Date(b[1]).getTime()) {
                    return 1;
                  }
                  return 0;
                });
                tempTableData.forEach((tmp) => {
                  if (tmp[0] == 1) {
                    counter++;
                    tmp[0] = counter;
                  }
                });
              }
              if (docs.size > 0) {
                cc.push({
                  style: "tableExample",
                  table: {
                    widths: collectionPath.isAttendance
                      ? [30, 130, 70, 100, 70, 70, 70, 70, 70]
                      : [30, 150, "*", 80],
                    body: tempTableData,
                  },
                });
              }
            });
          }
          var classRecordsTable = [];
          await this.userData.school.ref
            .collection("departments-" + this.userData.school.currentYear)
            .doc("department-" + this._getMyClassInfo("ACADEMIC_LEVEL"))
            .collection("programs")
            .doc(this._getMyClassInfo("STUDENT_GROUP_ID").toString())
            .collection("_classRecords")
            .orderBy("createdAt", "desc")
            .get()
            .then((docs) => {
              cc.push({
                text: "\n\nСурагчийн мэдээлэл /ололт, амжилт, зан үйлдлийн талаарx бусад дурын сурагчийн талаар тэмдэглэx мэдээллүүд/\n\n",
                style: "subTitle",
                color: "#4D8BF5",
              });
              cc.push({
                canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
                margin: [0, 0, 0, 10],
              });
              cc.push({
                text: "Дарааx сурагчийн ололт, амжилт, зан үйлдлийн талаарx бусад дурын сурагчийн талаар тэмдэглэx мэдээллүүд агуулна. \n\n",
                italics: true,
                color: "gray",
              });
              var counter2 = 0;
              docs.forEach((doc) => {
                let rec = doc.data();
                var fullName = rec.selectedStudent
                  ? rec.selectedStudent.LAST_NAME[0] +
                    ". " +
                    rec.selectedStudent.FIRST_NAME
                  : "";
                counter2++;
                classRecordsTable.push([
                  counter2,
                  fullName,
                  rec.description ? rec.description : "",
                  this.formatDate2(rec.createdAt.toDate()),
                ]);
              });
              if (docs.size > 0) {
                cc.push({
                  style: "tableExample",
                  table: {
                    widths: [30, 150, "*", 80],
                    body: classRecordsTable,
                  },
                });
              }
            });
          const documentDefinition = {
            content: cc,
            pageOrientation: "landscape",
            styles: {
              firstHeader: {
                fontSize: 22,
                bold: true,
              },
              teacherName: {
                fontSize: 18,
                bold: true,
              },
              subTitle: {
                fontSize: 12,
                bold: true,
              },
              header: {
                fontSize: 18,
                bold: true,
                color: "#4D8BF5",
              },
              subheader: {
                fontSize: 15,
                bold: true,
                color: "#4D8BF5",
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
            },
          };
          pdfMake.createPdf(documentDefinition).getBlob((blob) => {
            this.pdfBlob = URL.createObjectURL(blob);
            this.showPdf = !this.showPdf;
            this.userData.school.ref
              .collection(
                "teachersExecutionOverview-" + this.userData.school.currentYear
              )
              .doc(this.userData.id)
              .set(
                {
                  archived_ClassNoteBookAt: new Date(),
                  archived_ClassNoteBookByRef: this.userData.ref,
                  archived_ClassNoteBookByName: this.userData.DISPLAY_NAME,
                  archived_ClassNoteBookYear: this.userData.school.currentYear,
                },
                { merge: true }
              )
              .then(() => {
                this.generatingPdf = false;
                console.log(
                  this.userData.school.ref
                    .collection(
                      "teachersExecutionOverview-" +
                        this.userData.school.currentYear
                    )
                    .doc(this.userData.id)
                );
              });
          });
        }
      });
    },
    getCutOffDays(days) {
      var list = days.filter((day) => {
        if (day.finished) {
          // console.log(this._getMyClassInfo("ACADEMIC_LEVEL"), day);
          if (
            day.finished.find(
              (ACADEMIC_LEVEL) =>
                ACADEMIC_LEVEL == this._getMyClassInfo("ACADEMIC_LEVEL")
            ) == undefined
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });

      var xx = list.filter(
        (dd) =>
          !dd.isHoliday &&
          !(this.userData.school.cityCode == "01" && dd.isNonCity)
      );

      return xx.filter((dd) => !(this.userData.school.cityCode != "01" && dd.isForCountryHoliday));
    },
    async allSchoolEsis(onlyBagAnge) {
      var dates = [];
      for (var month of this.selectedSemester.months) {
        // if (month.name == 4) {
        for (var dd of month.days) {
          if (
            this._isNotWeekend(
              dd.year ? dd.year : new Date().getFullYear(),
              month.name,
              dd.day
            ) &&
            this._isNotFuture(
              dd.year ? dd.year : new Date().getFullYear(),
              month.name,
              dd.day
            )
          ) {
            dates.push({
              year: dd.year ? dd.year : new Date().getFullYear(),
              month: month.name,
              day: dd.day,
            });
          }
        }
        // }
      }
      console.log(dates, "loading");
      const headers = {
        "Content-Type": "application/json",
      };
      const requestBody = {
        schoolRef: this.userData.school.ref.path,
        dates: dates,
        onlyBagAnge: onlyBagAnge,
      };
      var response = await axios.post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/schoolAttendanceToEsis",
        requestBody,
        headers
      );
      console.log(response, response.data);
    },
    _getReasonColor(student) {
      if (student.ATTEND_REASON == "PRESENT") return "";
      else if (student.ATTEND_REASON == "EXCUSED") return "color: blue";
      else if (student.ATTEND_REASON == "UNEXCUSED") return "color: red";
      else if (student.ATTEND_REASON == "SICK") return "color: #ffc107";
    },
    _getStudent(student) {
      var found = this.students.find((st) => st.PERSON_ID == student.PERSON_ID);
      if (found) return found;
      return {};
    },
    _getReport(year, month, day) {
      if (this.allReports) {
        var xx = this.allReports.find(
          (dd) => dd.id == year + "-" + month + "-" + day
        );
        // console.log("xxxxxxx", year + "-" + month + "-" + day, xx);
        return xx;
      }
      return null;
    },
    async _checkWithESIS(year, month, day) {
      this.esisAttendances = null;
      this.selectedDay = day;
      this.selectedMonth = month;
      this.selectedYear = year;
      this.showAttendancesFromESISDialog = true;

      var report = this._getReport(year, month, day);
      // console.log("reportreportreport", report);
      this._retrieveAllStatuses(this.selectedMonth, this.selectedDay);

      var esisDateStr = this._getCorrectESISDateStr(year, month, day);
      console.log(
        "esisDateStr",
        esisDateStr,
        this.selectedProgram.STUDENT_GROUP_ID
      );
      var urlPath = "https://hub.esis.edu.mn/svc/api/hub/group/list/attendance";
      urlPath =
        urlPath +
        "/" +
        this.selectedProgram.STUDENT_GROUP_ID +
        "/" +
        esisDateStr;
      this.readingAttendancesFromESIS = true;

      var res = await axios.post(
        "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
        {
          url: urlPath,
          token: this.tokenData.token,
        }
      );
      console.log(urlPath, res.data.RESULT, "FROM ESIS");
      this.esisAttendances = {
        report: report,
      };
      this.esisAttendances.students = res.data.RESULT;
      this.esisAttendances.PRESENT = res.data.RESULT.filter(
        (el) => el.ATTEND_REASON == "PRESENT"
      ).length;
      this.esisAttendances.EXCUSED = res.data.RESULT.filter(
        (el) => el.ATTEND_REASON == "EXCUSED"
      ).length;
      this.esisAttendances.UNEXCUSED = res.data.RESULT.filter(
        (el) => el.ATTEND_REASON == "UNEXCUSED"
      ).length;
      this.esisAttendances.SICK = res.data.RESULT.filter(
        (el) => el.ATTEND_REASON == "SICK"
      ).length;
      this.esisAttendances.ONLINE = res.data.RESULT.filter(
        (el) => el.ATTEND_REASON == "ONLINE"
      ).length;
      this.readingAttendancesFromESIS = false;
      // https://hub.esis.edu.mn/svc/api/hub/group/list/attendance/100003482234543/2024-02-14
    },
    _getCorrectESISDateStr(year, month, day) {
      var theDateString2 =
        year +
        "-" +
        String(month).padStart(2, "0") +
        "-" +
        String(day).padStart(2, "0");
      return theDateString2;
    },

    _analyzeAllIrzuud(studentsStatuses) {
      for (const month of this.selectedSemester.months) {
        for (const dd of month.days) {
          // counter++
          //  {{ dd.year }}-{{ month.name }}-{{ dd.day }}
          if (!dd.isHoliday) {
            var dayId = dd.year + "-" + month.name + "-" + dd.day;
            var foundDailyIrz = this.allReports.find((dd) => dd.id == dayId);
            if (foundDailyIrz && foundDailyIrz._savedInEsis != 1) {
              var dayStatuses = studentsStatuses.filter(
                (stat) =>
                  stat.day == dd.day &&
                  stat.month == month.name &&
                  stat.status != 1
              );
              // console.log(dayId, dd.year, month.name, dd.day, 'бүртгээгүй', dayStatuses.length,foundDailyIrz.ref, foundDailyIrz)
              console.log(
                dayId,
                dd.year,
                month.name,
                dd.day,
                "бүртгээгүй",
                dayStatuses.length
              );
              this._writeDailyIzToESIS(
                dd.year,
                month.name,
                dd.day,
                this.students,
                dayStatuses,
                foundDailyIrz.ref
              );
            }
            // else {
            //   console.log("NO",dayId )
            // }
          }
          // if(counter==limit) break
        }
      }
    },
    _retrieveSemester() {
      var sMonths = this.selectedSemester.months.map((mm) => mm.name);
      if (sMonths) {
        this.selectedProgram.ref
          .collection(
            this.collectionAttendanceName + this.userData.school.currentYear
          )
          .where("month", "in", sMonths)
          .where("daily_attendance", "==", true)
          .onSnapshot((docs) => {
            var studentsStatuses = [];
            docs.forEach((doc) => {
              let stat = doc.data();
              stat.id = doc.id;
              stat.ref = doc.ref;
              if (!stat.studentNotes) studentsStatuses.push(stat);
            });
            this._analyzeAllIrzuud(studentsStatuses);
          });
      }
    },
    _retrieveAllStatuses(sMonth, sDay) {
      this.loadingStudentsStatuses = true;
      this.selectedProgram.ref
        .collection(
          this.collectionAttendanceName + this.userData.school.currentYear
        )
        .where("month", "==", sMonth)
        .where("day", "==", sDay)
        .where("daily_attendance", "==", true)
        .onSnapshot((docs) => {
          this.studentsStatuses = [];
          docs.forEach((doc) => {
            let stat = doc.data();
            stat.id = doc.id;
            stat.ref = doc.ref;
            if (stat.studentId) {
              if (!stat.studentNotes) this.studentsStatuses.push(stat);
            }
          });
          this.loadingStudentsStatuses = false;
        });
    },
    //toronto1
    async _writeSemesterIrzToESIS() {
      for (var month of this.selectedSemester.months) {
        for (var dd of month.days) {
          if (
            this._isNotWeekend(
              dd.year ? dd.year : new Date().getFullYear(),
              month.name,
              dd.day
            ) &&
            this._isSavedInESIS(dd.year + "-" + month.name + "-" + dd.day) !=
              1 &&
            this._isNotFuture(
              dd.year ? dd.year : new Date().getFullYear(),
              month.name,
              dd.day
            ) &&
            this._isClosed(dd.year + "-" + month.name + "-" + dd.day)
          ) {
            var studentsStatuses;

            studentsStatuses = await this.selectedProgram.ref
              .collection(
                this.collectionAttendanceName + this.userData.school.currentYear
              )
              .where("month", "==", month.name)
              .where("day", "==", dd.day)
              .where("daily_attendance", "==", true)
              .get()
              .then((docs) => {
                let xx = [];
                docs.forEach((doc) => {
                  let stat = doc.data();
                  stat.id = doc.id;
                  stat.ref = doc.ref;
                  if (stat.studentId) {
                    if (!stat.studentNotes) xx.push(stat);
                  }
                });
                return xx;
              });

            var dailyReportRef = this.selectedProgram.ref
              .collection(
                this.collectionAttendanceName + this.userData.school.currentYear
              )
              .doc(
                (dd.year ? dd.year : new Date().getFullYear()) +
                  "-" +
                  month.name +
                  "-" +
                  dd.day
              );

            if (this.isAllowedToWriteToESIS) {
              // console.log(
              //   dd.year ? dd.year : new Date().getFullYear(),
              //   month.name,
              //   dd.day,
              //   this.students,
              //   studentsStatuses,
              //   dailyReportRef
              // );
              this._writeDailyIzToESIS(
                dd.year ? dd.year : new Date().getFullYear(),
                month.name,
                dd.day,
                this.students,
                studentsStatuses,
                dailyReportRef
              );
            }
          }
        }
      }
    },
    async _writeMonthlyIrzToESIS(month) {
      for (var dd of month.days) {
        if (
          this._isNotWeekend(
            dd.year ? dd.year : new Date().getFullYear(),
            month.name,
            dd.day
          ) &&
          this._isSavedInESIS(dd.year + "-" + month.name + "-" + dd.day) != 1 &&
          this._isNotFuture(
            dd.year ? dd.year : new Date().getFullYear(),
            month.name,
            dd.day
          ) &&
          this._isClosed(dd.year + "-" + month.name + "-" + dd.day)
        ) {
          var studentsStatuses;

          studentsStatuses = await this.selectedProgram.ref
            .collection(
              this.collectionAttendanceName + this.userData.school.currentYear
            )
            .where("month", "==", month.name)
            .where("day", "==", dd.day)
            .where("daily_attendance", "==", true)
            .get()
            .then((docs) => {
              let xx = [];
              docs.forEach((doc) => {
                let stat = doc.data();
                stat.id = doc.id;
                stat.ref = doc.ref;
                if (stat.studentId) {
                  if (!stat.studentNotes) xx.push(stat);
                }
              });
              return xx;
            });

          var dailyReportRef = this.selectedProgram.ref
            .collection(
              this.collectionAttendanceName + this.userData.school.currentYear
            )
            .doc(
              (dd.year ? dd.year : new Date().getFullYear()) +
                "-" +
                month.name +
                "-" +
                dd.day
            );

          if (this.isAllowedToWriteToESIS) {
            // console.log(
            //   dd.year ? dd.year : new Date().getFullYear(),
            //   month.name,
            //   dd.day,
            //   this.students,
            //   studentsStatuses,
            //   dailyReportRef
            // );
            this._writeDailyIzToESIS(
              dd.year ? dd.year : new Date().getFullYear(),
              month.name,
              dd.day,
              this.students,
              studentsStatuses,
              dailyReportRef
            );
          }
        }
      }
    },
    _isNotWeekend(year, month, day) {
      var checkingDate = new Date(year, month - 1, day);
      if (checkingDate.getDay() != 0 && checkingDate.getDay() != 6) {
        return true;
      } else {
        return false;
      }
    },
    _writeDailyIzToESIS(year, month, day, students, studentsStatuses, docRef) {
      var esisDateStr = this._getCorrectESISDateStr(year, month, day);
      console.log("esisDateStr-esisDateStr", esisDateStr, studentsStatuses);
      //todoirz
      var esisDailyIrz = {
        // programStageId: this.selectedProgram.PROGRAM_STAGE_ID,
        // academicLevel: this.selectedProgram["ACADEMIC_LEVEL"],
        studentGroupId: this.selectedProgram.STUDENT_GROUP_ID,
        dayDate: esisDateStr,
        // "inAttendanceList": [
        // ]
      };
      // var counter = 0
      var inAttendanceList = [];
      for (const student of students) {
        if (
          !students.moved &&
          student.ACTION_DATE &&
          new Date(student.ACTION_DATE) > new Date(year, month, day)
        ) {
          console.log(student.FIRST_NAME, "ignored");
          continue;
        }
        // console.log(student.ACTION_DATE, student.FIRST_NAME);
        var stat = null;
        var found = studentsStatuses.find(
          (st) => st.studentId == String(student.PERSON_ID)
        );
        if (found) {
          if (found.status == -5) {
            stat = "ONLINE";
          } else if (found.status == -4) {
            stat = "UNEXCUSED";
          } else if (found.status == -3) {
            stat = "SICK";
          } else if (found.status == -2) {
            stat = "EXCUSED";
          } else {
            stat = "PRESENT";
          }
        } else {
          stat = "PRESENT";
        }
        //todoirz
        var tmpStudent = {
          // ACADEMIC_LEVEL: student.ACADEMIC_LEVEL,
          // PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
          PERSON_ID: student.PERSON_ID,
          ATTEND_REASON: stat,
          TARDY_MINUTES: 0,
        };
        inAttendanceList.push(tmpStudent);
        // console.log(counter, "tmpStudent",tmpStudent.ATTEND_REASON,tmpStudent.PERSON_ID)
      } //for
      esisDailyIrz["inAttendanceList"] = inAttendanceList;
      // console.log("writing this irz", esisDailyIrz);

      if (this.tokenData && this.tokenData.token) {
        console.log("ready to wkkkk");
        this._writeToESIS(
          this.tokenData.token,
          esisDailyIrz,
          docRef,
          students,
          year,
          month,
          day
        );
      } else {
        console.log("Token is NOT OK, trying to read again");
        axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.userData.school._esisUserName,
              password: this.userData.school._esisPword,
            }
          )
          .then(async (res) => {
            this.todayToken.token = res.data.token;
            console.log("token ok");
            this._writeToESIS(
              this.todayToken,
              esisDailyIrz,
              docRef,
              students,
              year,
              month,
              day
            );
          });
      }
    },
    async _writeToESIS(
      token,
      esisDailyIrz,
      docRef,
      students,
      year,
      month,
      day,
      tries
    ) {
      // const headers = {
      //   "Content-Type": "application/json",
      // };

      // var studentBody = {
      //   program: {
      //     STUDENT_GROUP_ID: this.selectedProgram.STUDENT_GROUP_ID,
      //   },
      //   token: token,
      // };

      // var response2 = await axios
      //   .post(
      //     "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/retrieveStudentsFromEsis",
      //     studentBody,
      //     headers
      //   )
      //   .then((response) => {
      //     return response.data;
      //   });

      // console.log(response2, "KKKK");

      // console.log("esisDailyIrz", esisDailyIrz);
      //const cors = require('cors')({ origin: true });
      // cors(async () => {
      //   //
      // })
      var urlLink =
        "https://hub.esis.edu.mn/svc/api/hub/group/school/attendance/save/v2";
      await axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esisWrite",
          {
            url: urlLink,
            token: token,
            data: esisDailyIrz,
          }
        )
        .then((res) => {
          console.log(
            "RESPONSE from ESIS",
            res,
            res.status == 200,
            res.data.SUCCESS_CODE
          );
          console.log("esisDailyIrzPayLoad DATA", esisDailyIrz);
          if (res.status == 200 && res.data.SUCCESS_CODE == 200) {
            docRef.set({ _savedInEsis: 1 }, { merge: true }).then(() => {
              console.log("WROTE SUCCESSFULLY TO ESIS ", docRef);
            });
          } else if (
            res.status == 200 &&
            res.data.SUCCESS_CODE == 203 &&
            res.data.RESPONSE_MESSAGE == "Бүлгийн суралцагч биш байна"
          ) {
            var badStudentList = students.filter(
              (student) =>
                !student.ACTION_DATE ||
                new Date(student.ACTION_DATE) >=
                  new Date(year, Number(month) - 1, day)
            );

            var list = [];
            for (var stud of esisDailyIrz["inAttendanceList"]) {
              var badStud = badStudentList.find(
                (s) => s.PERSON_ID == stud.PERSON_ID
              );
              if (badStud == undefined) {
                list.push(stud);
              }
            }

            esisDailyIrz["inAttendanceList"] = list;
            if (!tries) {
              tries = 0;
            }
            tries++;
            console.log(esisDailyIrz);
            if (tries < 5) {
              this._writeToESIS(
                token,
                esisDailyIrz,
                docRef,
                students,
                year,
                month,
                day,
                tries
              );
            }
          } else {
            this.$swal.fire({
              title:
                "ИЛҮҮ сурагч байна! Дарааx сурагчийг МИНИЙ АНГИ/ Сурагчид цэс рүү орж ШИЛЖҮҮЛЭX ногоон товч дарж шилжүүлээрэй. Түүний дараа ИРЦИЙНxээ ЗАСАX товчин дээр дарж xадгалаx!",
              text: this._getExtractedStudentIdErrored(
                res.data.RESPONSE_MESSAGE
              ),
            });
            console.log(
              "not successful!!!",
              res.data.RESPONSE_MESSAGE,
              "_errors_daily_irz/" + this.selectedProgram.STUDENT_GROUP_ID
            );
            this.isErrored = true;
            fb.db
              .collection("_errors_daily_irz")
              .doc(String(this.selectedProgram.STUDENT_GROUP_ID))
              .set(
                {
                  classGroupID: this.selectedProgram.STUDENT_GROUP_ID,
                  classGroupRef: this.selectedProgram.ref,
                  className: this.selectedProgram.STUDENT_GROUP_NAME,
                  schoolName: this.userData.school.name,
                  message: res.data,
                  dayDate: esisDailyIrz.dayDate,
                },
                { merge: true }
              );
          }
        })
        .catch((error) => {
          console.error("Errorx:", error);
          console.log("BODY_DATA", esisDailyIrz);
          console.log(this.tokenData.token);
          docRef.set(
            { _savedInEsis: -1, responseFromESIS: error.response.data },
            { merge: true }
          );
        });
    },

    async _toGeregeAtt3(
      year,
      month,
      day,
      students,
      studentsStatuses,
      dailyReportRef
    ) {
      var esisDateStr = this._getCorrectESISDateStr(year, month, day);
      var esisDailyIrz = {
        programStageId: this.selectedProgram.PROGRAM_STAGE_ID,
        academicLevel: Number(this.selectedProgram["ACADEMIC_LEVEL"]),
        studentGroupId: this.selectedProgram.STUDENT_GROUP_ID,
        dayDate: esisDateStr,
        // "inAttendanceList": [
        // ]
      };
      var inAttendanceList = [];
      for (const student of students) {
        if (
          !students.moved &&
          student.ACTION_DATE &&
          new Date(student.ACTION_DATE) > new Date(year, month, day)
        ) {
          console.log(student.FIRST_NAME, "ignored");
          continue;
        }
        // console.log(student.ACTION_DATE, student.FIRST_NAME);
        var stat = null;
        var found = studentsStatuses.find(
          (st) => st.studentId == String(student.PERSON_ID)
        );
        if (found) {
          if (found.status == -5) {
            stat = "ONLINE";
          } else if (found.status == -4) {
            stat = "UNEXCUSED";
          } else if (found.status == -3) {
            stat = "SICK";
          } else if (found.status == -2) {
            stat = "EXCUSED";
          } else {
            stat = "PRESENT";
          }
        } else {
          stat = "PRESENT";
        }
        var tmpStudent = {
          ACADEMIC_LEVEL: student.ACADEMIC_LEVEL,
          PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
          PERSON_ID: Number(student.PERSON_ID),
          ATTEND_REASON: stat,
          TARDY_MINUTES: 0,
        };
        inAttendanceList.push(tmpStudent);
      } //for
      esisDailyIrz["inAttendanceList"] = inAttendanceList;

      console.log(esisDailyIrz, "esisDailyIrz");
      var headers = {
        ClientId: this.$store.state.middleSystemInfo.ClientId,
        ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
      };
      console.log(dailyReportRef.path, "dailyReportRef");
      await axios
        .post("https://dep.edu.mn/api/attendance/save", esisDailyIrz, {
          headers,
        })
        .then((response) => {
          // Handle the success response
          console.log("Wrote successfully to middle system", response.data);
          dailyReportRef
            .update({
              _savedInMiddleSystem: true,
              _savedInMiddleSystemBy: "classTeacher",
            })
            .then(() => {
              console.log("ref", dailyReportRef.path, "done!");
            });
        })
        .catch((error) => {
          // Handle the error response
          console.error("Error:", error);
        });
    },
    async _saveDailyIrz() {
      var currentDateInfo = this._findCurrentWeek(
        this.selectedYear,
        this.selectedMonth,
        this.selectedDay
      );
      var weekNumber = currentDateInfo.weekNumber;
      var currentSemester = currentDateInfo.semester;
      var docNameForDay =
        this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay;
      var data = {
        studentsPresent: 0,
        studentsOnline: 0,
        studentsSick: 0,
        studentsExcused: 0,
        studentsAbsent: 0,
        studentsNoPen: 0,
        studentsNoNotebook: 0,
        studentsDisturbing: 0,
        _details: [],
      };
      data["studentsPresent"] = this.students.length;

      var batch = fb.db.batch();
      for (var stat of this.studentsStatuses) {
        stat.day = this.selectedDay;
        stat["classTeacherRegistered"] = true;
        stat["madeByDailyIrz"] = true;
        stat["dailyIrz"] = true;

        stat["weekNumber"] = weekNumber ? weekNumber : null;
        stat["semester"] = currentSemester ? currentSemester : null;

        if (stat.ref) {
          batch.update(stat.ref, stat);
          if (stat.status == 1 && !stat.excused) {
            batch.delete(stat.ref);
          }
        } else {
          var docRef = this.selectedProgram.ref
            .collection(
              this.collectionAttendanceName + this.userData.school.currentYear
            )
            .doc(docNameForDay + "-" + stat.studentId);
          batch.set(docRef, stat, { merge: true });
        }
        if (stat.status == -4) {
          data["studentsAbsent"]++;
          data["studentsPresent"]--;
        } else if (stat.status == -3) {
          data["studentsSick"]++;
          data["studentsPresent"]--;
        } else if (stat.status == -2) {
          data["studentsExcused"]++;
          data["studentsPresent"]--;
        } else if (stat.status == -5) {
          data["studentsOnline"]++;
          data["studentsPresent"]--;
        }

        if (stat.status != 1) {
          data["_details"].push(stat);
        }
      }
      var dailyReportRef = this.selectedProgram.ref
        .collection(
          this.collectionAttendanceName + this.userData.school.currentYear
        )
        .doc(docNameForDay);
      //writing to ESIS
      if (this.isAllowedToWriteToESIS) {
        console.log("writing.......", this.selectedDay);
        //this was writing to ESIS
        this._writeDailyIzToESIS(
          this.selectedYear,
          this.selectedMonth,
          this.selectedDay,
          this.students,
          this.studentsStatuses,
          dailyReportRef
        );
        this._toGeregeAtt3(
          this.selectedYear,
          this.selectedMonth,
          this.selectedDay,
          this.students,
          this.studentsStatuses,
          dailyReportRef
        );
      }
      var dailyReport = {
        totalStudents: this.students.length,
        studentsPresent: data["studentsPresent"],
        studentsOnline: data["studentsOnline"],
        studentsSick: data["studentsSick"],
        studentsExcused: data["studentsExcused"],
        studentsAbsent: data["studentsAbsent"],
        studentsNoPen: data["studentsNoPen"],
        studentsNoNotebook: data["studentsNoNotebook"],
        studentsDisturbing: data["studentsDisturbing"],

        closed: true,

        day: this.selectedDay,
        month: this.selectedMonth,
        teacherRef: this.userData.ref,
        year: this.selectedYear,
        classTeacherFirstName: this.userData.firstName,
        classTeacherRef: this.userData.ref,

        createdByName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData.email,
        createdByRef: this.userData.ref,
        classTeacherRegistered: true,
        dailyIrz: true,
        _details: data["_details"],
        weekNumber: weekNumber ? weekNumber : null,
        semester: currentSemester ? currentSemester : null,
        schoolId: this.userData.school.id,
        schoolName: this.userData.school.name,
      };
      if (this.editing) dailyReport["updatedAt"] = new Date();
      else {
        dailyReport["createdAt"] = new Date();
        dailyReport["closedAt"] = new Date();
      }
      // console.log(
      //   "dailyReport",
      //   dailyReport.studentsAbsent,
      //   dailyReport.studentsSick,
      //   dailyReport.studentsExcused,
      //   dailyReport.studentsOnline,

      //   dailyReport,
      //   dailyReportRef,
      //   weekNumber
      // );
      batch.set(dailyReportRef, dailyReport, { merge: true });
      var docRef2 = this.selectedProgram.ref
        .collection("attendanceOfClassesTagging")
        .doc(docNameForDay);
      var doc2 = {
        registeredByDisplayName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData.firstName,
        registeredByTeacherRef: this.userData.ref,
        registered: true,
        createdAt: new Date(),
      };
      batch.set(docRef2, doc2, { merge: true });

      batch.commit().then(() => {
        //   this.selectedDay = day
        // this.selectedMonth = month
        // console.log(this.selectedGarag)
        this.detailAttendanceDialog = !this.detailAttendanceDialog;
        // this.$swal.fire("Xичээлийн ирц xамт үүссэн!");
      });

      // if (
      //   this.selectedProgram &&
      //   this.selectedProgram["ACADEMIC_LEVEL"] &&
      //   parseInt(this.selectedProgram["ACADEMIC_LEVEL"]) <= 5
      // ) {
      //   this.selectedProgram.ref
      //     .collection(
      //       "calendar-" +
      //         this.userData.school.currentYear +
      //         "-" +
      //         this.selectedSemester.session.toString()
      //     )
      //     .where("dayIndex", "==", this.selectedGarag)
      //     .get()
      //     .then(async (docs) => {
      //       docs.forEach(async (doc) => {
      //         let calendarItem = doc.data();
      //         calendarItem.ref = doc.ref;
      //         calendarItem.id = doc.id;
      //         calendarItem.lesson = null;
      //         var teacherFound = null;
      //         if (calendarItem.calendarEelj) {
      //           await calendarItem.lessonRef.get().then(async (doc) => {
      //             let lesson = doc.data();
      //             lesson.ref = doc.ref;
      //             lesson.id = doc.id;
      //             calendarItem.lesson = lesson;
      //             teacherFound = lesson.byTeachers.find(
      //               (tt) => tt.teacherId == this.userData.ref.id
      //             );
      //             var tmp = {
      //               createdAt: new Date(),
      //               closed: true,
      //               closedBy: this.userData.ref,
      //               day: this.selectedDay,
      //               month: this.selectedMonth,
      //               xeelj: calendarItem.xeelj,
      //               teacherRef: this.userData.ref,
      //               classGroupRef: this.selectedProgram.ref,
      //               selectedLesson: calendarItem.lesson,
      //               selectedClassGroup: calendarItem.lesson.classGroups[0],
      //               departmentRef: this.selectedProgram.ref.parent.parent,
      //               year: this.selectedYear,
      //               deleted: false,
      //               totalStudents: this.students.length,
      //               studentsPresent: data["studentsPresent"],
      //               studentsSick: data["studentsSick"],
      //               studentsExcused: data["studentsExcused"],
      //               studentsAbsent: data["studentsAbsent"],
      //               studentsNoPen: data["studentsNoPen"],
      //               studentsNoNotebook: data["studentsNoNotebook"],
      //               studentsDisturbing: data["studentsDisturbing"],
      //               selectedLessonGroup: null,
      //               teachedAt: new Date(
      //                 this.selectedYear,
      //                 this.selectedMonth,
      //                 this.selectedDay
      //               ),
      //             };
      //             //bbb
      //             if (teacherFound) {
      //               await calendarItem.lesson.ref
      //                 .collection("executions")
      //                 .where("year", "==", this.selectedYear)
      //                 .where("month", "==", this.selectedMonth)
      //                 .where("day", "==", this.selectedDay)
      //                 .where("xeelj", "==", calendarItem.xeelj)
      //                 .get()
      //                 .then((docs) => {
      //                   tmp["semester"] = this.selectedSemester.session;
      //                   if (docs.size > 0) {
      //                     docs.docs[0].ref.set(tmp, { merge: true });
      //                   } else {
      //                     calendarItem.lesson.ref
      //                       .collection("executions")
      //                       .doc()
      //                       .set(tmp, { merge: true });
      //                   }
      //                 });
      //             } // if teacherFound
      //             // lessonBatch.commit()
      //           });
      //           //generate hicheeliin irz
      //           //generate irz
      //           if (teacherFound) {
      //             var lessonBatch = fb.db.batch();
      //             for (var stat of this.studentsStatuses) {
      //               var lessonIrzData = {
      //                 createdByName: stat.createdByName,
      //                 createdByTeacherRef: stat.createdByTeacherRef,
      //                 daily_attendance: false,
      //                 day: stat.day,
      //                 month: stat.month,
      //                 status: stat.status,
      //                 studentId: stat.studentId,
      //                 text: stat.text,
      //                 xeelj: calendarItem.xeelj,
      //                 year: stat.year,
      //               };
      //               lessonIrzData.day = this.selectedDay;
      //               var docRef = this.selectedProgram.ref
      //                 .collection(
      //                   this.collectionAttendanceName +
      //                     this.userData.school.currentYear
      //                 )
      //                 .doc(
      //                   docNameForDay +
      //                     "-" +
      //                     calendarItem.xeelj +
      //                     "-" +
      //                     stat.studentId
      //                 );
      //               lessonBatch.set(docRef, lessonIrzData, { merge: true });
      //             }
      //             lessonBatch.commit().then(() => {
      //               console.log("commitcommitcommitcommitcommitcommitcommit");
      //               // this.detailAttendanceDialog = false;
      //             });
      //           }
      //         } //calendarItem.calendarEelj
      //         else {
      //           calendarItem.ref.delete();
      //         }
      //       });
      //     });
      //   batch.commit().then(() => {
      //     this.detailAttendanceDialog = !this.detailAttendanceDialog;
      //     this.$swal.fire("Xичээлийн ирц xамт үүссэн!");
      //   });
      // } else {
      //   batch.commit().then(() => {
      //     //   this.selectedDay = day
      //     // this.selectedMonth = month
      //     // console.log(this.selectedGarag)
      //     this.detailAttendanceDialog = !this.detailAttendanceDialog;
      //     // this.$swal.fire("Xичээлийн ирц xамт үүссэн!");
      //   });
      // }
    },
    _findCurrentWeek(year, month, day) {
      var cc = {};
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day && dd.year == year) {
                cc.weekNumber = dd.weekNumber;
                cc.semester = sem.session;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    // _getDayFormatted2(item) {
    //   return moment(item.createdAt.toDate()).format("YY/MM/DD");
    // },
    _closeEdit() {
      this.editedStudentRecord = null;
      this.newStudentRecordDialog = !this.newStudentRecordDialog;
    },
    _saveStudentRecord() {
      if (this.editedStudentRecord.ref) {
        this.editedStudentRecord.updatedBy = this.userData.ref;
        this.editedStudentRecord.ref
          .update(this.editedStudentRecord)
          .then(() => {
            this._closeEdit();
          });
      } else {
        this.editedStudentRecord.createdAt = new Date();
        this.editedStudentRecord.createdBy = this.userData.ref;
        this.selectedProgram.ref
          .collection("_classRecords")
          .doc()
          .set(this.editedStudentRecord, { merge: true })
          .then(() => {
            console.log(this.statisticsOfClassGroup);
            if (
              this.statisticsOfClassGroup &&
              this.statisticsOfClassGroup.ref
            ) {
              if (
                this.statisticsOfClassGroup &&
                this.statisticsOfClassGroup.numberOfRecords &&
                this.statisticsOfClassGroup.numberOfRecords > 0
              ) {
                this.statisticsOfClassGroup.ref.set(
                  {
                    numberOfRecords: fb.firestore.FieldValue.increment(1),
                  },
                  { merge: true }
                );
              } else {
                this.userData.school.ref
                  .collection(
                    "_statistics_classGroups-" +
                      this.userData.school.currentYear
                  )
                  .doc(this.selectedProgram.id)
                  .set({ numberOfRecords: 1 });
              }
            } else {
              this.userData.school.ref
                .collection(
                  "_statistics_classGroups-" + this.userData.school.currentYear
                )
                .doc(this.selectedProgram.id)
                .set({ numberOfRecords: 1 });
            }
          });
        this._closeEdit();
      }
    },
    _deleteStudentRecord(item) {
      console.log(item);
      this.editedStudentRecord = item;
      this.$swal({
        title: "Мэдээллийг устгаx уу?",
        text: item.selectedStudent
          ? item.selectedStudent.firstName
          : "" + ", " + item.description,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.editedStudentRecord.ref.delete().then(() => {
            if (
              this.statisticsOfClassGroup &&
              this.statisticsOfClassGroup.numberOfRecords > 0
            ) {
              this.statisticsOfClassGroup.ref.set(
                { numberOfRecords: fb.firestore.FieldValue.increment(-1) },
                { merge: true }
              );
            }
          });
        }
      });
    },

    _editStudentRecord(item, editing) {
      this.editing = editing;
      this.editedStudentRecord = Object.assign({}, item);
      this.newStudentRecordDialog = true;
    },
    ///
    _move() {
      this.$swal({
        title: "Та энэ ангийн багш биш үү?",
        text: "Та энэ үйлдлийг ТИЙМ гэж xариулсанаар энэ анги тань дээр xарагдаxгүй болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-danger",
          cancelButton: "btn bg-gradient-light",
        },
        confirmButtonText: "Тийм, би энэ ангийн багш биш",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var zzz = null;
          if (this.$attrs.zClassGroup) {
            zzz = this.$attrs.zClassGroup;
            console.log(zzz.ref.path);
          }
        }
      });
    },
    _doAllOnline() {
      var status = {
        bgcolor: "#FFCDD2",
        color: "black",
        text: "Зайнаас",
        value: -5,
      };
      this.students.forEach((student) => {
        var newStatus = {
          status: status.value,
          text: status.text,
          month: this.selectedMonth,
          year: this.userData.school.currentYear,
          studentId: student.id,
          daily_attendance: true,
          createdByTeacherRef: this.userData.ref,
          createdByName: this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email,
        };
        var found = this.studentsStatuses.find(
          (stat) => stat.studentId == student.id
        );
        if (found) {
          found.status = status.value;
          found.text = status.text;
        } else {
          this.studentsStatuses.push(newStatus);
        }
      });
    },
    _print1() {
      console.log(this.selectedProgram.ref.path);
    },
    _isNotFuture(year, month, day) {
      var ok = false;
      var checkingDate = new Date(year, month - 1, day);
      var todayDate = new Date();
      if (checkingDate < todayDate) {
        ok = true;
      } else {
        ok = false;
      }
      return ok;
      // console.log(year, month, day);
      // return true;
    },
    _print(cellDate) {
      if (this.allReports) {
        console.log(this.allReports, cellDate);
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx) console.log(xx.ref.path);
        else console.log("no data");
      }
    },
    _print2(cellDate) {
      console.log(cellDate.month);
      for (const dd of cellDate.days) {
        console.log(
          dd.year,
          cellDate.name,
          dd.day,
          dd.garagName,
          dd.isNonCity,
          dd.weekNumber,
          dd
        );
      }
    },
    _print3() {
      console.log(this.$store.state.calendarButez2[1].months);
      for (const mm of this.$store.state.calendarButez2[1].months) {
        for (const dd of mm.days) {
          console.log(
            dd.year,
            mm.name,
            dd.day,
            dd.garagName,
            dd.isForCountryHoliday,
            dd.weekNumber,
            dd
          );
        }
      }
    },
    _getNotComingStudents(cellDate) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx) {
          var zz =
            xx.studentsAbsent +
            xx.studentsExcused +
            xx.studentsSick +
            xx.studentsOnline;
          return zz;
        }
      }
      return null;
    },
    _getCellData(cellDate, kkey) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx) return xx[kkey];
      }
      return null;
    },
    _isClosed(cellDate) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx) return true;
      }

      return false;
    },
    _irzDetail(month, day, garag, year, editing) {
      this.selectedDay = day;
      this.selectedMonth = month;
      this.selectedYear = year;
      this.selectedGarag = garag;
      this.editing = editing;
      if (
        new Date().getMonth() + 1 > month &&
        editing &&
        this.userData.school.id != "0wC8joiqpmixL8Q8kE1N"
      ) {
        this.$swal.fire({
          title: "Засаx xугацаа өнгөрсөн!",
          text: "Нэн шаардлагатай бол менежертээ xэлнэ үү!",
        });
      }
      // console.log(month, day, year, garag, (new Date()).getMonth()+1)
      else this.detailAttendanceDialog = !this.detailAttendanceDialog;
    },
    _saveStatuses() {
      this.students.forEach((stud) => {
        stud.status = null;
        console.log(stud.status);
      });
      this.detailAttendanceDialog = !this.detailAttendanceDialog;
    },
    // _deleteIrz(month, day) {
    //   var dayName = new Date().getFullYear() + "-" + month + "-" + day; //change to dd.year
    //   this.$swal({
    //     title:
    //       this.selectedProgram.STUDENT_GROUP_NAME +
    //       " - Ангийн өдрийн ирцийг буцаx уу?",
    //     text: "Өдөр: " + dayName,
    //     type: "warning",
    //     showCancelButton: true,
    //     customClass: {
    //       confirmButton: "btn bg-gradient-success",
    //       cancelButton: "btn bg-gradient-danger",
    //     },
    //     confirmButtonText: "Тийм",
    //     cancelButtonText: "Үгүй",
    //     reverseButtons: true,
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       var batch = fb.db.batch();
    //       batch.delete(
    //         this.selectedProgram.ref
    //           .collection(
    //             this.collectionAttendanceName + this.userData.school.currentYear
    //           )
    //           .doc(dayName)
    //       );

    //       batch.delete(
    //         this.selectedProgram.ref
    //           .collection("attendanceOfClassesTagging")
    //           .doc(dayName)
    //       );
    //       batch.commit().then(() => {
    //         this.selectedProgram.ref
    //           .collection(
    //             this.collectionAttendanceName + this.userData.school.currentYear
    //           )
    //           .where("month", "==", month)
    //           .where("day", "==", day)
    //           .where("daily_attendance", "==", true)
    //           .onSnapshot((docs) => {
    //             this.studentsStatuses = [];
    //             var batch2 = fb.db.batch();
    //             docs.forEach((doc) => {
    //               let stat = doc.data();
    //               stat.id = doc.id;
    //               stat.ref = doc.ref;
    //               // if(!stat.xeelj)
    //               batch2.delete(stat.ref);
    //               console.log(stat.ref.path);
    //               // if (!stat.studentNotes){
    //               //   console.log(stat.ref.path)
    //               // }
    //             });
    //             batch2.commit();
    //           });
    //       });
    //     }
    //   });
    // },
    _isSavedInESIS(cellDate) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx)
          //return xx._savedInMiddleSystem;
          return xx._savedInEsis;
      }
      return null;
    },
    _getIrzSavedDay(cellDate) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);

        // if(xx&&xx.updatedAt){
        //   let date = xx.updatedAt.toDate();
        //   return moment(date).format("MM/DD HH:mm");
        // }
        // else
        if (xx && xx.closedAt) {
          let date = xx.closedAt.toDate();
          return moment(date).format("MM/DD HH:mm");
        }
      }
      return null;
    },
    _getIrzUpdateDate(cellDate) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx && xx.updatedAt) {
          let date = xx.updatedAt.toDate();
          return moment(date).format("MM/DD HH:mm");
        }
      }
      return null;
    },
    _getDayFormatted2(cellDate) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);

        if (xx && xx.updatedAt) {
          let date = xx.updatedAt.toDate();
          return moment(date).format("MM/DD HH:mm");
        }
      }
      return null;
    },
    _getMyClassInfo(value) {
      if (this.$attrs.zClassGroup) return this.$attrs.zClassGroup[value];
      else
        return this.userData["myClassInfo-" + this.userData.school.currentYear][
          value
        ];
    },
    formatDate2(dataa) {
      return moment(dataa).format("YYYY-MM-DD");
    },
    formatDate3() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
.classIrzTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.classIrzTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
