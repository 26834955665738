<template>
  <v-container fluid>
    <!-- Leave Requests Table -->
    <!-- <h2>{{ currentClassGroup.STUDENT_GROUP_NAME }}</h2> -->
    <v-data-table
      class="mt-10"
      v-if="students"
      :headers="headers"
      :items="students"
      item-value="id"
      hide-details
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:item.note1="{ item }">
        <span
          class="px-4 py-1"
          style="background-color: red; color: white; border-radius: 4px"
          v-if="item.note1 > 0"
        >
          {{ item.note1 }}
        </span>
        <span v-else class="px-4">0</span>
      </template>
      <template v-slot:item.note2="{ item }">
        <span
          class="px-4 py-1"
          style="background-color: red; color: white; border-radius: 4px"
          v-if="item.note2 > 0"
        >
          {{ item.note2 }}
        </span>
        <span v-else class="px-4">0</span>
      </template>

      <template v-slot:item.actions="{}">
        <v-btn
          small
          elevation="0"
          @click="
            $swal.fire(
              'Тун удаxгүй энд ямар багшийн xичээл дээр зөрчил гаргасан талаар мэдээ гарна!'
            )
          "
          >Дэлгэрэнгүй</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  data() {
    return {
      students: null,
      selectedTerm: 2,
      terms: [1, 2, 3, 4],
      headers: [
        { text: "No", value: "index", width: "2%" },
        { text: "Овог, нэр", value: "name" },
        { text: "Xоцорсон цаг", value: "numberOfTardy" },
        { text: "Бусдад саад болсон", value: "note1" },
        { text: "Xичээлийн xэрэглэл дутуу", value: "note2" },
        { text: "Үйлдэл", value: "actions", sortable: false },
      ],
      leaveRequests: [
        {
          id: 1,
          name: "А.Амармэнд",
          group: "9а",
          role: "АУБ",
          startDate: "2/18/2025",
          endDate: "2/18/2025",
          days: 1,
          status: "Чөлөөтэй",
        },
      ],
      allAttendances: null,
    };
  },
  computed: {
    ...sync("*"),
  },
  props: {
    currentClassGroup: {
      required: true,
      type: Object,
    },
  },
  methods: {},
  async created() {
    await this.currentClassGroup.ref
      .collection("attendances-" + this.$store.state.schoolStartYear)
      .where("daily_attendance", "==", false)
      .where("semester", "==", this.selectedTerm)
      .get()
      .then((docs) => {
        this.allAttendances = [];
        docs.forEach((doc) => {
          let att = doc.data();
          att.ref = doc.ref;
          att.id = doc.id;
          this.allAttendances.push(att);
        });
      });
    this.userData.school.ref
      .collection("students-" + this.$store.state.schoolStartYear)
      .orderBy("firstName", "asc")
      .where(
        "STUDENT_GROUP_ID",
        "==",
        this.currentClassGroup["STUDENT_GROUP_ID"]
      )
      .onSnapshot((docs) => {
        this.students = [];
        var counter = 0;
        docs.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;

          item.numberOfTardy = 0;
          item.numberOfTardy2 = 0;
          item.note1 = 0;
          item.note2 = 0;

          var allStudentAtts = this.allAttendances.filter(
            (att) => String(att.studentId) == String(item.PERSON_ID)
          );

          allStudentAtts.forEach((att) => {
            if (att.TARDY_MINUTES) {
              item.numberOfTardy = item.numberOfTardy + att.TARDY_MINUTES;
            }

            if (att.note && att.note.notes) {
              item.numberOfTardy2 = item.numberOfTardy2 + att.note.notes.length;
              item.note1 =
                item.note1 +
                att.note.notes.filter((nn) => nn.scorePenaltyId == 1).length;
              item.note2 =
                item.note2 +
                att.note.notes.filter((nn) => nn.scorePenaltyId == 2).length;
            }
          });

          //25217
          item.name = item.LAST_NAME
            ? item.LAST_NAME[0] + "." + item.FIRST_NAME
            : item.FIRST_NAME;
          if (!item.moved) {
            counter++;
            item.index = counter;
            this.students.push(item);
          }
        });
      });
  },
};
</script>

<style scoped>
.v-alert {
  font-weight: bold;
}
</style>
